<script>
import TablePage from '@/found/components/table_page';
import Vue from 'vue';
// 审核
export default {
  extends: TablePage,
  components: {
  },
  data() {
    return {
    };
  },
  async mounted() {
    await this.getConfigList('anxious_detail_report_list');
  },

  methods: {
    setColumn(v) {
      const rowData = v;
      // if (rowData.field === 'branchCodeList') {
      //   rowData.search = 'true';
      //   rowData.type = 'select';
      //   rowData.apiUrl = '/mdm/mdmOrgController/findOrgList';
      //   rowData.optionsKey = {
      //     label: 'orgName',
      //     value: 'orgCode',
      //   };
      //   rowData.multiple = true;
      //   rowData.isSearch = true;
      //   rowData.paramsName = 'orgName';
      //   rowData.paramsFixed = {
      //     orgType: 'area',
      //   };
      // }
      return rowData;
    },
  },
};
</script>
